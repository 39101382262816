import React from "react"
import { ApolloProvider } from "@apollo/client";
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import apolloClient from "./client"
// <GoogleReCaptchaProvider reCaptchaKey="6Lc4SrsZAAAAAK_J_PUGKRsE1xhbd4ncrDAK_sxm">

export const wrapRootElement = ({ element }) => (
  // <GoogleReCaptchaProvider reCaptchaKey="6LcNc7sZAAAAAC3y3Ub10Kn6jlhdXN6LCmicLIJb">
  <ApolloProvider client={apolloClient}>{element}</ApolloProvider>
  // </GoogleReCaptchaProvider>
)

