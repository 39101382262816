// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-backup-tsx": () => import("./../src/pages/backup.tsx" /* webpackChunkName: "component---src-pages-backup-tsx" */),
  "component---src-pages-call-center-tsx": () => import("./../src/pages/callCenter.tsx" /* webpackChunkName: "component---src-pages-call-center-tsx" */),
  "component---src-pages-default-backup-tsx": () => import("./../src/pages/default/backup.tsx" /* webpackChunkName: "component---src-pages-default-backup-tsx" */),
  "component---src-pages-default-call-center-tsx": () => import("./../src/pages/default/callCenter.tsx" /* webpackChunkName: "component---src-pages-default-call-center-tsx" */),
  "component---src-pages-default-index-tsx": () => import("./../src/pages/default/index.tsx" /* webpackChunkName: "component---src-pages-default-index-tsx" */),
  "component---src-pages-default-reason-codes-tsx": () => import("./../src/pages/default/reasonCodes.tsx" /* webpackChunkName: "component---src-pages-default-reason-codes-tsx" */),
  "component---src-pages-default-rtc-tsx": () => import("./../src/pages/default/rtc.tsx" /* webpackChunkName: "component---src-pages-default-rtc-tsx" */),
  "component---src-pages-default-whitelist-tsx": () => import("./../src/pages/default/whitelist.tsx" /* webpackChunkName: "component---src-pages-default-whitelist-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-rtc-tsx": () => import("./../src/pages/rtc.tsx" /* webpackChunkName: "component---src-pages-rtc-tsx" */),
  "component---src-pages-whitelist-tsx": () => import("./../src/pages/whitelist.tsx" /* webpackChunkName: "component---src-pages-whitelist-tsx" */)
}

