import { ApolloClient, gql, HttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import Cookies from "universal-cookie"
const cookies = new Cookies()
export interface UserConfig {
  username?: string
  roles?: string[]
  access?: string[]
  action?: string
  email?: string
  isFullyLoggedIn?: boolean
  authyID?: string | boolean
  isLoggedIn?: boolean
}

export interface Config {
  user: UserConfig
  isSidePanelCollapsed?: boolean
  lastInteractionDate?: string
}

export const GET_DECODED_TOKEN = gql`
  query TokenDecoded {
    user @client
  }
`

export const GET_CONFIG = gql`
  query Config {
    isSidePanelCollapsed @client
    showLoginForm @client
    isLoggedIn @client
    lastInteractionDate @client
    username @client
  }
`

export const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`

export const updateConfig = (client: ApolloClient<unknown>) => {
  if (typeof window !== "undefined") {
    const config = window.localStorage.getItem("config")
    const configDecoded = config ? JSON.parse(config) : {}
    client.writeQuery({
      query: GET_CONFIG,
      data: {
        isSidePanelCollapsed: configDecoded?.isSidePanelCollapsed || false,
        showLoginForm: configDecoded?.showLoginForm || "",
        isLoggedIn: configDecoded?.isLoggedIn || false,
        username: configDecoded?.username || "",
        lastInteractionDate: configDecoded?.lastInteractionDate || "",
      },
    })
  }
}

export const updateTokenDecoded = (client: ApolloClient<unknown>) => {
  if (typeof window !== undefined) {
    const tokenDecoded = getAuthTokenDecoded()
    client.writeQuery({
      query: GET_DECODED_TOKEN,
      data: {
        user: {
          username: tokenDecoded?.user?.username || "",
          roles: tokenDecoded?.user?.roles || [],
          access: tokenDecoded?.user?.access || [],
          action: tokenDecoded?.user?.action || "",
          email: tokenDecoded?.user?.email || "",
          isFullyLoggedIn: tokenDecoded?.user?.isFullyLoggedIn || false,
          authyID: tokenDecoded?.user?.authyID || false,
          isLoggedIn: tokenDecoded?.user?.isLoggedIn || false,
        },
        isSidePanelCollapsed: tokenDecoded?.isSidePanelCollapsed || false,
      },
    })
  }
}

// apolloClient.writeData({ data: { isLoggedIn: false } });
export interface ApolloClientOptionInterface {
  uri?: string
  credentials?: string
  // tslint:disable-next-line: no-any
  customHeaders?: any
  fetch?: WindowOrWorkerGlobalScope["fetch"]
  isCognito?: boolean
}
export default ({
  uri,
  fetch,
  customHeaders,
  isCognito,
}: ApolloClientOptionInterface): ApolloClient<unknown> => {
  const cache = new InMemoryCache()
  // tslint:disable-next-line: no-any
  // tslint:disable-next-line: no-any
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // tslint:disable-next-line: no-any
    let config: any =
      (typeof window !== undefined &&
        window.localStorage &&
        window.localStorage.getItem("config")) ||
      "" ||
      ""
    try {
      config = JSON.parse(config)
    } catch (e) {
      // console.log(e);
    }
    const token =
      (typeof window !== "undefined" && localStorage.getItem("token")) ||
      "" ||
      ""

    const validToken = config?.idToken?.jwtToken
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: validToken,
        // "x-api-key": "da2-3mfuuh5y3zbuja35rqgjnz7oni",
        ...customHeaders,
      },
    }
  })

  const httpConfig: ApolloClientOptionInterface = {
    uri: uri || "http://localhost:4000/api",
  }
  if (fetch) {
    httpConfig.fetch = fetch
  }

  const httpLink = new HttpLink(httpConfig)

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    // assumeImmutableResults: true,
  })

  updateTokenDecoded(apolloClient)
  updateConfig(apolloClient)

  // apolloClient.writeQuery({
  //   query: IS_LOGGED_IN,
  //   data: {
  //   },
  // });
  return apolloClient
}

// Login with cookies
export const setAuthToken = (value: string) => {
  // Remove usage of token
  if (typeof window !== undefined) {
    window.localStorage.removeItem("token")
  }
  cookies.set("_auth", value, {
    domain: "vt.team",
    path: "/",
    expires: new Date(new Date().getTime() + 1000 * 3600 * 8),
  })
}
export const getAuthTokenDecoded = () => {
  const token = cookies.get("_auth")
  return token ? JSON.parse(atob(token.split(".")[1])) : {}
}

export const deleteAuthToken = () => {
  cookies.remove("_auth", { domain: "vt.team", path: "/" })
}
